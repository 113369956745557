/* Colors! */
$bg_color: #000;
$black: #000;
$black_t40: rgb(0 0 0 / 40%);
$black_t10: rgb(0 0 0 / 10%);
$white: #fff;
$white_fc: #fcfcfc;
$white_f3: #f3f3f3;
$gray: #757575;
$gray_seperator: #242424;
$gray_dark: #333;
$gray_border: #666;
$gray_legal_text: #737373;
$gray_background: #666;
$gray_progress_bar: #808080;
$gray_light: #999;
$lighter_gray: #cbcbcb;
$dim_gray: #737373;
$gray_80: #ccc;
$gray_44: #707070;
$gray_84: #848484;
$gray_a5: #a5a5a5;
$gray_d0: #d0d0d0;
$gray_7b: #7b7b7b;
$gray_a9: #a9a9a9;
$gray_8c: #8c8c8c;
$gray_bf: #bfbfbf;
$gray_bb: #bbb;
$gray_cf: #cfcfcf;
$gray_ef: #efefef;
$gray_f9: #f9f9f9;
$gray_divider: #d8d8d8;
$gray_snow: #fafafa;
$gray_60: #999;
$redError: #e00;
$common_question_border_grey: #cacaca;
$red: #f00;
$banner_gray: #2c2e34;
$bg-textarea: #1a1a1a;
$bg-equifit: #080808;
$bg-header: #0a0a0a;
$gray_6e: #6e6e6e;
$gray_67: #676767;
$gray_9a: #9a9a9a;
$white_f4: #f4f4f4;
$bar-color1: #3860c8;
$bar-color2: #2c3ed6;
$bar-color3: #201be4;
$bar-color4: #7012a0;
$bar-color5: #970d7f;
$bar-color6: #bf085d;
$green_color: #008e75;
$gray-7c: #7c878e;
$gray-border: rgb(255 255 255 / 50%);
$black-input-bg: rgb(255 255 255 / 0%);
$proof-of-vaccination-blue: #0056b3;
